import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { IButton } from 'types/cms';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from './style.module.scss';

interface IProp {
  data: IButton;
}

export const Item: React.FC<IProp> = ({ data }) => {
  const { button_text, button_URL, button_style, button_image } = data;

  if (button_style === 'Image') {
    return (
      <Link prefetch={false} href={button_URL} as={button_URL}>
        <a href={button_URL}>
          <div className={styles.imgWrapper}>
            {button_image?.url && (
              <Image
                layout="fill"
                className={styles.img}
                src={button_image.url}
                alt={button_text}
              />
            )}
          </div>
        </a>
      </Link>
    );
  }

  return (
    <Button
      variant={button_style === 'Outlined' ? 'outlined' : 'contained'}
      color="secondary"
      href={button_URL}
      className={cx(styles.button, styles[button_style])}
    >
      <Typography variant="h6">{button_text}</Typography>
    </Button>
  );
};
