import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import React from 'react';
import { IButton } from 'types/cms';

import Grid from '@material-ui/core/Grid';

import { Item } from './item';
import styles from './style.module.scss';

export enum CMSButtonStyle {
  TwoColumns = 'Two_Column',
  ThreeColumns = 'Three_Column',
}
interface IProps {
  id: number;
  layout: CMSButtonStyle;
  button_collections?: IButton[];
  button_collection?: IButton;
}

export const CMSButton: React.FC<IProps> = ({ layout, button_collections, button_collection }) => {
  return (
    <CMSChildrenContainer>
      <div
        className={
          layout === CMSButtonStyle.TwoColumns
            ? styles.twoColumnsContainer
            : styles.threeColumnsContainer
        }
      >
        {button_collection && <Item data={button_collection} />}
        {button_collections && (
          <Grid container spacing={3}>
            {button_collections.map(i => (
              <Grid key={i.id} item xs={12} md={layout === CMSButtonStyle.TwoColumns ? 6 : 4}>
                <Item data={i} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </CMSChildrenContainer>
  );
};
